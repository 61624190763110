/**
 * Created by maqiang34.
 *  数据监控中心
 */
import * as API from '../../utils/request';

export default {

    //公告信息
    getNotify: params => { return API.GET('msg/getNotify', params)},
    addNotify: params => { return API.POSTARRAY('msg/addNotifMsg', params)},
    delNotify: params => { return API.POSTARRAY('msg/delNotifMsg', params)},

}

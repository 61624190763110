<template>
    <div class="container">
        <el-tabs @tab-click="handleClick" class="my_tab">
            <el-tab-pane :label="`未读消息(${page.wd})`" status="0"></el-tab-pane>
            <el-tab-pane :label="`已读消息(${page.yd})`" status="1"></el-tab-pane>
            <el-tab-pane :label="`回收站(${page.hsz})`" status="-1"></el-tab-pane>
        </el-tabs>
        <div class="handle-box">
             <span>
                <el-button v-show="status==0" type="primary" icon="el-icon-plus" @click="add()">发布</el-button>
                <el-button v-show="status==0" type="info" icon="el-icon-check" @click="read()">已读</el-button>
                <el-button v-show="status!=-1" type="warning" icon="el-icon-delete" @click="del()">删除</el-button>
                <el-button v-show="status==-1" type="warning" icon="el-icon-delete" @click="del()">彻底删除</el-button>
                <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
             </span>
            <span class="fr">
                <el-select clearable v-model="filter_msg_type" placeholder="消息类型" class="handle-select mr10">
                    <el-option v-for="item in msgType" :key="item.value" :label="item.name"
                               :value="item.value"></el-option>
                </el-select>
                <el-input v-model="filter_msg_key" placeholder="关键字" clearable style="width: 120px;" class="mr10"></el-input>

                <el-date-picker v-model="setime" type="datetimerange" align="right" unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD"
                                format="YYYY-MM-DD"
                                :picker-options="pickerOptions" class="mr10"></el-date-picker>
                <el-button
                        type="primary"
                        icon="el-icon-search"
                        @click="initPageData"
                >查询</el-button>
            </span>
        </div>
        <div class="table">
            <el-table :data="page.rows" @selection-change="handleSelectionChange" v-loading="loading"
                      element-loading-text="拼命加载中" border style="width: 100%; height:50%">
                <el-table-column type="selection" width="35"></el-table-column>
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="标题">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        {{scope.row.createDate+ '【'+scope.row.typeNme+'】' +scope.row.title }}
                    </template>
                </el-table-column>
                <el-table-column label="内容">
                    <template slot-scope="scope">
                        {{ scope.row.content }}
                    </template>
                </el-table-column>
                <el-table-column prop="remarks" label="备注"></el-table-column>
                <el-table-column prop="createName" label="发送人" width="150"></el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button v-show="0==scope.row.status" type="text" icon="el-icon-check"
                                   @click="handle_read(scope.$index, scope.row)">已读
                        </el-button>
                        <el-button type="text" icon="el-icon-delete" @click="handle_del(scope.$index, scope.row)">删除
                        </el-button>
                    </template>

                </el-table-column>
            </el-table>
            <el-pagination @current-change="devlogChange" :current-page="page.pageNum"
                           :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                           :total="page.total"></el-pagination>
        </div>
        <!-- 添加修改设备信息-->
        <el-dialog :title="notifydial.dialtitle" width="600px" :visible.sync="notifydialShow"
                   :close-on-click-modal="false">
            <el-form>
                <el-form-item label="通知类型" label-width="100px">
                    <el-select v-model="notifydial.type" placeholder="状态" class="handle-select mr8" style="width: 100%">
                        <el-option v-for="item in msgType" :key="item.value" :label="item.name"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标题" label-width="100px">
                    <el-input v-model="notifydial.title"></el-input>
                </el-form-item>
                <el-form-item label="内容" label-width="100px">
                    <el-input v-model="notifydial.content" type="textarea" :rows="5"></el-input>
                </el-form-item>
                <el-form-item label="附件" label-width="100px">
                    <el-input v-model="notifydial.files"></el-input>
                </el-form-item>
                <el-form-item label="备注" label-width="100px">
                    <el-input v-model="notifydial.remarks" type="textarea" :rows="2"></el-input>
                </el-form-item>
                <el-form-item label="接收人" label-width="100px">
                    <el-input v-model="notifydial.usernames" disabled>
                        <el-button slot="append" icon="el-icon-plus" @click="showUsers=true"></el-button>
                    </el-input>
                </el-form-item>
                <div v-show="showUsers"
                     style="margin-left: 100px;border: 1px solid rgb(220, 223, 230); margin-top: -23px; position: absolute; width: 77%; height: 300px; background: white; overflow: hidden;">
                    <el-tree
                            lazy show-checkbox default-expand-all ref="tree" node-key="{type}+'_'+{id}"
                            :load="loadNode" :props="defaultProps" style="height: 240px;overflow:overlay;">
                         <span class="custom-tree-node" slot-scope="{ node, data }">
                            <i :class="DTSTATUS[data.type].icon" :style="{color:DTSTATUS[data.type].color}"></i>
                           <span>{{ node.label}}</span>
                        </span>
                    </el-tree>
                    <div slot="footer" class="dialog-footer"
                         style="bottom: 0px; position: absolute;text-align: center;width: 100%;border-top: 1px solid #dcdfe6;padding: 5px;">
                        <el-button @click="showUsers=false">取 消</el-button>
                        <el-button type="primary" @click="setUsers()">确 定</el-button>
                    </div>
                </div>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="notifydialShow=false">取 消</el-button>
                <el-button type="primary" @click="addNotify()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/javascript">

    import COMM from '../../api/base/api_comm';
    import USER from '../../api/base/api_user';
    import MSG from '../../api/base/api_msg';
    import syscomm from '../../utils/syscomm';

    export default {
        data() {
            return {
                //表格
                status: 0,
                loading: true,
                msgType: [], MSGTYPES: {},
                filter_msg_type: null, filter_msg_key: null,
                selectDatas: undefined,
                page: {wd: 0, yd: 0, hsz: 0, rows: [], pageNum: 1, pageSize: 10, total: 0},
                DTSTATUS: syscomm.DEPTTYPES,
                //弹出框
                showUsers: false,
                treeData: undefined,
                defaultProps: {children: 'zones', label: 'name', isLeaf: 'leaf'},
                notifydialShow: false,
                notifydial: {
                    type: 0,
                    title: undefined,
                    content: undefined,
                    files: undefined,
                    userIds: undefined,
                    usernames: undefined
                },

                setime: [syscomm.getStartTime(7), syscomm.getStartTime(0)],
                pickerOptions: {
                    shortcuts: [
                        {
                            text: '今天', onClick(picker) {
                                picker.$emit('pick', [syscomm.getStartTime(0.5), new Date()]);
                            }
                        },
                        {
                            text: '昨天', onClick(picker) {
                                picker.$emit('pick', [syscomm.getStartTime(1), new Date()]);
                            }
                        },
                        {
                            text: '近3天', onClick(picker) {
                                picker.$emit('pick', [syscomm.getStartTime(3), new Date()]);
                            }
                        },
                        {
                            text: '最近一周', onClick(picker) {
                                picker.$emit('pick', [syscomm.getStartTime(7), new Date()]);
                            }
                        },
                        {
                            text: '最近一个月', onClick(picker) {
                                picker.$emit('pick', [syscomm.getStartTime(30), new Date()]);
                            }
                        },
                        {
                            text: '最近三个月', onClick(picker) {
                                picker.$emit('pick', [syscomm.getStartTime(90), new Date()]);
                            }
                        }]
                },

            };
        },
        components: {},
        methods: {
            init() {
                COMM.getObjType({type: 0}).then((data) => {
                    this.msgType = data;
                    for (let type of data) {
                        this.MSGTYPES[type.value] = type.name;
                    }
                    this.initPageData();
                });
            },
            //
            initPageData() {
                this.loading = true;
                USER.getPageMsg({
                    status: this.status,
                    type: this.filter_msg_type,
                    key: this.filter_msg_key,
                    startTime: null != this.setime ? this.setime[0] : null,
                    endTime: null != this.setime ? this.setime[1] : null,
                    pageNum: this.page.pageNum, pageSize: this.page.pageSize,
                }).then((res) => {//初始化项目设备
                    var pageData = res.pageData, rows = pageData.list;
                    for (var row of rows) {
                        row.typeNme = this.MSGTYPES[row.type];
                    }
                    this.page.total = pageData.total;
                    this.page.rows = rows;
                    this.page.wd = res.wd;
                    this.page.yd = res.yd;
                    this.page.hsz = res.hsz;
                    this.loading = false;
                });
            },

            devlogChange(pageNum) {
                this.page.pageNum = pageNum;
                this.initPageData();
            },

            read() {
                if (this.selectDatas && this.selectDatas.length > 0) {
                    var ids = [];
                    for (var item of this.selectDatas) {
                        ids.push(item.id);
                    }
                    this.changestatus(1, ids);
                } else {
                    this.$message.error("请选择需要操作的数据！");
                }
            },
            handle_read(index, row) {
                debugger
                var ids = [row.id];
                USER.changestatus({status: 1, 'ids': ids}).then((res) => {
                    if (200 == res.code) {
                        this.initPageData();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            add() {
                this.notifydial = {
                    dialtitle: '发布公告',
                    type: 0,
                    title: undefined,
                    content: undefined,
                    files: undefined,
                    userIds: undefined,
                    usernames: undefined,
                    'icon-class': 'el-icon-edit'
                };
                this.notifydialShow = true;
            },


            changestatus(status, ids) {
                USER.changestatus({status: status, 'ids': ids}).then((res) => {
                    if (200 == res.code) {
                        this.initPageData();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },

            setUsers() {
                this.showUsers = false;
                var nodes = this.$refs.tree.getCheckedNodes(true, false);
                if (nodes.length == 0) {
                    return;
                }
                var userids = [], names = [];
                for (var user of nodes) {
                    if (user.isSelect) {
                        names.push(user.name);
                        userids.push(user.id);
                    }
                }
                this.notifydial.userIds = userids;
                this.notifydial.uids = userids.join(",")
                this.notifydial.usernames = names.join(";")
            },

            handleClick(tab, event) {
                this.status = tab.$attrs.status;
                this.initPageData();
            },

            del() {
                if (this.selectDatas && this.selectDatas.length > 0) {
                    this.handle_del(-1, this.selectDatas);
                } else {
                    this.$message.error("请选择需要操作的数据！");
                }
            },
            handle_del(index, row) {
                this.$confirm('您确定要删除选中的消息吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    var ids = [];
                    if (-1 == index) {
                        for (var item of row) {
                            ids.push(item.id);
                        }
                    } else {
                        ids.push(row.id);
                    }
                    if (-1 == this.status) {
                        USER.delMsg({'ids': ids}).then((res) => {
                            if (200 == res.code) {
                                this.notifydialShow = false;
                                this.$message.success("删除成功！");
                                this.initPageData();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                    } else {
                        this.changestatus(-1, ids)
                    }
                }).catch(() => {

                });
            },

            handleSelectionChange(selectDatas) {
                this.selectDatas = selectDatas;
            },
            //
            addNotify() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                let data = this.notifydial;
                data.createDate = undefined;
                data.updateDate = undefined;
                MSG.addNotify(data).then((res) => {
                    if (200 === res.code) {
                        this.notifydialShow = false;
                        this.initPageData();
                        this.$message.success(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            },
            //动态加载树节点
            async loadNode(node, resolve) {
                if (node.level === 0) {
                    // debugger
                    let data = await COMM.getRootNode()
                    return resolve(data);
                }
                if (node.data && (node.data.isSelect || node.data.leaf)) {
                    return resolve([]);
                }
                COMM.getUserTree({id: node.data.id, isLoadUser: true}).then((data) => {
                    return resolve(data)
                });
            }
        }
        ,
        mounted() {
            this.init();
        }
    }
</script>
<style scoped>

</style>

